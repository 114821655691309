//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
    name: "MobileFooterNav",
    components: {
    CreateEntityDropdown: () => import('@/components/NavbarDropdowns/CreateEntityDropdown.vue'),
  }
}
